import _ from 'lodash'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  ErrorMessage,
  Form,
  useFormikContext,
} from 'formik'
import { isSafari } from 'react-device-detect';
import {
  customStylesLanguageDropdown,
} from '../../ui/dropdown/custom-styles'
import combineClassNames from '../../helpers/combineClassNames';
import {
  BirthdayInput,
  Button,
  Dropdown,
  Input,
  InputPhone,
  InputRadio,
  Link,
  TokenButton,
} from '../../ui'
import {
  useThemeConfig,
} from '../../hook'
import useStyles from './registration-style'

const RegistrationForm = ({
  countryCallingCodeOptions,
  formConfig,
  formDisabled,
  genderSelection,
  lastNameFirst,
  localeOptions,
  onLoginClick,
  onRequestEmailTokenError,
  onRequestEmailTokenSuccess,
  onRequestSmsTokenError,
  onRequestSmsTokenSuccess,
  onSocialSignIn,
  privacyUrl,
  requiredFields,
  salutationOptions,
  showEmailVerificationCodeInput,
  showVerificationCodeInput,
  socialAvailable,
  supportedRegisterApproaches,
  termsUrl,
}) => {
  const { t } = useTranslation()
  const { getConfig } = useThemeConfig()
  const {
    errors,
    isSubmitting,
    isValid,
    setFieldValue,
    setFieldTouched,
    submitForm,
    values,
  } = useFormikContext()

  const birthdayInputFormat = getConfig('config.pages.registrationForm.birthdayInputFormat')

  const handlePhoneChange = ({ countryCallingCode, localPhoneNumber }) => {
    setFieldValue('countryCallingCode', countryCallingCode)
    setFieldValue('localPhoneNumber', localPhoneNumber)
    const phone = !_.isEmpty(countryCallingCode) && !_.isEmpty(localPhoneNumber)
      ? `${countryCallingCode}${localPhoneNumber}`
      : ''
    setFieldValue('phone', phone)
  }

  const handleLocalePreferenceChange = (event) => {
    setFieldValue('locale', _.get(event, 'value'))
  }
  const handleSalutationChange = (event) => {
    setFieldValue('salutation', _.get(event, 'value'))
  }

  const handleBirthdayChange = (value) => {
    if (!_.isEmpty(value)) {
      setFieldTouched('dateOfBirth')
    }
    setFieldValue('dateOfBirth', value)
  }

  const handleSubmitForm = () => {
    submitForm()
  }

  // styles
  const styles = useStyles({ isSafari })

  const renderErrorMessage = (msg) => <p className={styles.errorStyle}>{msg}</p>

  return (
    <div className={styles.register}>
      <p className={styles.titleStyle}>{t('screens.registration.register')}</p>

      {/* TODO: make a social sign in component */}
      { _.some(socialAvailable, ['available', true])
        && (
        <>
          {
            _.map(socialAvailable, (socialMedia) => {
              if (socialMedia.available) {
                return (
                  <Button
                    key={socialMedia.name}
                    addFacebook
                    fontWeight={600}
                    size="1.6"
                    uppercase
                    hasIconleft
                    dark
                    padding="1.25rem"
                    background={socialMedia.name}
                    text={t('screens.login.facebook')}
                    onClick={() => onSocialSignIn(socialMedia.name)}
                  />
                )
              }
              return null
            })
            }
          <p className={styles.orStyle}>
            -
            {' '}
            {t('screens.login.or')}
            {' '}
            -
          </p>
        </>
        )}
      <Form noValidate>
        {
          _.get(formConfig, 'email') && (
            <Input
              formik
              uppercase
              label={t('screens.registration.form.email', { context: 'label' })}
              name="email"
              autoComplete="user-email"
              placeholder={t('screens.registration.form.email', { context: 'placeholder' })}
              required={requiredFields.email || _.every(supportedRegisterApproaches, { inputType: 'email' })}
            />
          )
        }
        {
          _.get(formConfig, 'email.requireConfirmation', false) && (
            <Input
              formik
              uppercase
              label={t('screens.registration.form.emailConfirmation', { context: 'label' })}
              name="emailConfirmation"
              autoComplete="user-email-confirmation"
              placeholder={t('screens.registration.form.emailConfirmation', { context: 'placeholder' })}
              required={requiredFields.emailConfirmation}
            />
          )
        }
        {
          !_.isEmpty(_.find(supportedRegisterApproaches, { verificationType: 'emailToken' })) && (
            <div className={styles.getSmsButtonContainer}>
              <TokenButton
                dark
                disabled={
                  _.isEmpty(values.email)
                  || _.has(errors, 'email')
                }
                email={_.get(values, 'email', '')}
                onSuccess={onRequestEmailTokenSuccess}
                onError={onRequestEmailTokenError}
              />
            </div>
          )
        }
        {
          showEmailVerificationCodeInput && (
            <Input
              formik
              label={t('screens.registration.form.token', { context: 'email' })}
              name="emailToken"
              required
            />
          )
        }
        {
          _.get(formConfig, 'password') && (
            <Input
              formik
              uppercase
              label={t('screens.registration.form.password', { context: 'label' })}
              name="password"
              type="password"
              autoComplete="new-password"
              placeholder={t('screens.registration.form.password', { context: 'placeholder' })}
              required={requiredFields.password}
            />
          )
        }
        {
          _.get(formConfig, 'passwordConfirmation') && (
            <Input
              formik
              uppercase
              label={t('screens.registration.form.passwordConfirmation', { context: 'label' })}
              name="passwordConfirmation"
              type="password"
              autoComplete="new-password"
              placeholder={t('screens.registration.form.passwordConfirmation', { context: 'placeholder' })}
              required={requiredFields.passwordConfirmation}
            />
          )
        }
        {/* {
          _.get(formConfig, 'salutation') && (
            <div className={styles.genderContainer}>
              <p
                className={combineClassNames([
                  styles.asInputStyle,
                  requiredFields.salutation && styles.requiredLabel,
                ])}
              >
                {t('screens.registration.gender.title')}
              </p>
              <div className={styles.boxGender}>
                {
                  _.map(salutationSelection, (salutation) => (
                    <div className={styles.gender} key={`radio-${salutation}`}>
                      <InputRadio
                        formik
                        id={`registration-salutation-${salutation}`}
                        // label={t(`screens.registration.salutation.${salutation}`)}
                        label={salutation}
                        name="salutation"
                        value={salutation}
                      />
                    </div>
                  ))
                }
              </div>
              <ErrorMessage name="salutation" render={renderErrorMessage} />
            </div>
          )
        } */}
        {
          _.get(formConfig, 'salutation') && (
            <Dropdown
              label={t('screens.registration.form.salutation.title')}
              className={styles.dropdown}
              placeholder="--"
              customStyles={customStylesLanguageDropdown}
              options={salutationOptions}
              onChange={handleSalutationChange}
              isSearchable={false}
              values={_.find(salutationOptions, { value: values.salutation })}
              required={requiredFields.salutation}
            />
          )
        }
        {
          lastNameFirst ? (
            <>
              <Input
                formik
                uppercase
                label={t('screens.registration.form.lastName', { context: 'label' })}
                name="lastName"
                autoComplete="given-name"
                placeholder={t('screens.registration.form.lastName', { context: 'placeholder' })}
                required={requiredFields.lastName}
              />
              <Input
                formik
                uppercase
                label={t('screens.registration.form.firstName', { context: 'label' })}
                name="firstName"
                autoComplete="family-name"
                placeholder={t('screens.registration.form.firstName', { context: 'placeholder' })}
                required={requiredFields.firstName}
              />
            </>
          ) : (
            <>
              <Input
                formik
                uppercase
                label={t('screens.registration.form.firstName', { context: 'label' })}
                name="firstName"
                autoComplete="family-name"
                required={requiredFields.firstName}
                placeholder={t('screens.registration.form.firstName', { context: 'placeholder' })}
              />
              <Input
                formik
                uppercase
                label={t('screens.registration.form.lastName', { context: 'label' })}
                name="lastName"
                autoComplete="given-name"
                required={requiredFields.lastName}
                placeholder={t('screens.registration.form.lastName', { context: 'placeholder' })}
              />
            </>
          )
        }
        {
          _.get(formConfig, 'gender') && (
            <>
              <div className={styles.genderContainer}>
                <p className={combineClassNames([styles.asInputStyle, requiredFields.gender && styles.requiredLabel])}>{t('screens.registration.gender.title')}</p>
                <div className={styles.boxGender}>
                  {
                    _.map(genderSelection, (gender) => (
                      <div className={styles.gender} key={`radio-${gender}`}>
                        <InputRadio
                          formik
                          id={`registration-gender-${gender}`}
                          label={t(`screens.registration.gender.${gender}`)}
                          name="gender"
                          value={gender}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
              <ErrorMessage name="gender" render={renderErrorMessage} />
            </>
          )
        }
        <BirthdayInput
          uppercase
          label={t('screens.registration.form.birthday')}
          name="dateOfBirth"
          inputFormat={birthdayInputFormat}
          required={requiredFields.dateOfBirth}
          onChange={handleBirthdayChange}
        />
        <ErrorMessage name="dateOfBirth" render={renderErrorMessage} />
        {
          _.get(formConfig, 'affiliatedUserMembershipCode') && (
            <Input
              formik
              uppercase
              label={t('screens.registration.form.affiliatedUserMembershipCode', { context: 'label' })}
              name="affiliatedUserMembershipCode"
              required={requiredFields.affiliatedUserMembershipCode}
              placeholder={t('screens.registration.form.affiliatedUserMembershipCode', { context: 'placeholder' })}
            />
          )
        }
        {
          _.size(localeOptions) > 1
          && (
          <Dropdown
            label={t('screens.registration.form.locale')}
            className={styles.dropdown}
            placeholder="--"
            customStyles={customStylesLanguageDropdown}
            options={localeOptions}
            onChange={handleLocalePreferenceChange}
            isSearchable={false}
            values={_.find(localeOptions, { value: values.locale })}
          />
          )
        }
        <InputPhone
          formik
          uppercase
          label={t('screens.registration.form.phone')}
          name="phone"
          required={requiredFields.phone || _.every(supportedRegisterApproaches, { inputType: 'phone' })}
          countryCallingCodeOptions={countryCallingCodeOptions}
          defaultValue={{
            countryCallingCode: values.countryCallingCode,
          }}
          onChange={handlePhoneChange}
        />
        <div className={styles.getSmsButtonContainer}>
          <TokenButton
            dark
            disabled={_.isEmpty(values.localPhoneNumber)}
            phone={`${_.get(values, 'countryCallingCode', '')}${_.get(values, 'localPhoneNumber', '')}`}
            onSuccess={onRequestSmsTokenSuccess}
            onError={onRequestSmsTokenError}
          />
        </div>
        {
          showVerificationCodeInput
            ? (
              <Input
                formik
                label={t('screens.registration.form.token')}
                name="token"
                required={requiredFields.token}
              />
            )
            : ''
        }
        {/*
          // TODO: make registration form fields configable
          <Input
            formik
            label={t('screens.registration.form.password')}
            name="password"
            type="password"
            required={requiredFields.password}
          />
          <Input
            formik
            label={t('screens.registration.form.passwordConfirmation')}
            name="passwordConfirmation"
            type="password"
            required={requiredFields.password}
          />
        */}
        <p className={styles.requireStyle}>{t('screens.registration.required')}</p>
        <p className={styles.terms}>
          <Trans i18nKey="screens.registration.terms">
            By signing up,you agree to our
            <Link gatsbyLink to={termsUrl} target="_blank" className={styles.termsLink}>Terms &amp; Conditions</Link>
            and
            <Link gatsbyLink to={privacyUrl} target="_blank" className={styles.termsLink}>Privacy Policy</Link>
            .
          </Trans>
        </p>
        <div className={styles.buttons}>
          <Button
            dark
            disabled={formDisabled || isSubmitting || !isValid}
            text={t('screens.registration.buttons.submit')}
            onClick={handleSubmitForm}
            className={styles.button}
          />
          <Button
            border
            text={t('screens.registration.buttons.haveAnAccount')}
            onClick={onLoginClick}
            className={styles.button}
          />
        </div>
      </Form>
    </div>
  )
}

export default RegistrationForm
