/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import URI from 'urijs'
import { Formik } from 'formik'
import {
  cancelRequest,
  useAuth,
  useAddresses,
  useSystemSettings,
  useUser,
  useOmnitechApp,
} from 'react-omnitech-api'
import {
  useAccount,
  useAlert,
  useAnalytics,
  useCart,
  useI18n,
  useLink,
  useOrderMethod,
  // usePriceTemplate,
} from '../../hook'
import {
  getSocialMediaAvailable,
  getRegistrationFormInitialValues,
  transformRegistrationFormValidationSchema,
} from '../../helpers'
import RegistrationForm from './registration-form'
import RegistrationView from './registration-view'

function RegistrationController({
  location, pathContext,
}) {
  // prepare hook
  const {
    getSupportedRegisterApproaches,
    getRegistrationApproach,
  } = useAccount()
  const alert = useAlert()
  const { navigate } = useLink()
  const { t } = useTranslation()
  const { fetchCountries } = useAddresses()
  const { getSystemSetting } = useSystemSettings()
  const { auth, createRegistration, setAuth } = useAuth()
  const {
    cart,
    cartId,
    getParams,
    mergeCart,
    updateCart,
  } = useCart()
  // const { code: CART_PRICE_TEMPLATE_KEY } = usePriceTemplate()
  const {
    orderMethod,
    store,
  } = useOrderMethod()
  const { availableLocales, locale } = useI18n()
  const { createUsersOmniAuthOtps } = useUser()
  const { api } = useOmnitechApp()
  const { trackEvent } = useAnalytics()

  // hook with dependencies
  const preferredCountryCodes = getSystemSetting('country.preferred_country_codes')
  const genderSelection = getSystemSetting('account.gender_selection', [])
  const salutationSelection = getSystemSetting('account.salutation_selection', [])
  const formConfig = getSystemSetting('account.user_registration_fields', {})
  const lastNameFirst = getSystemSetting('last_name_first', false)
  const supportedRegisterApproaches = getSupportedRegisterApproaches()
  // [FL] Don't use page.privacy_url or page.terms_url from system settings!
  //      They are not for frontend
  const privacyUrl = '/pages/privacy'
  const termsUrl = '/pages/terms'
  // TODO get array all social sign in enable
  const socialAvailable = getSocialMediaAvailable(getSystemSetting)

  // internal states
  const [countriesEntities, setCountriesEntities] = useState([])
  const [defaultCountryCallingCode, setDefaultCountryCallingCode] = useState('')
  const [formDisabled, setFormDisabled] = useState(true)
  const [pageReady, setPageReady] = useState(false)
  const [showEmailVerificationCodeInput, setShowEmailVerificationCodeInput] = useState(false)
  const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(false)

  // local variable
  const redirectUrl = useMemo(() => _.get(location, 'state.redirectUrl'), [location])
  const callbackState = useMemo(() => _.get(location, 'state.callbackState'), [location])
  const defaultValues = useMemo(() => _.get(location, 'state.values'), [location])
  const seoTitle = t('screens.registration.seo.title')

  // locale options
  const defaultLocale = locale
  const localeOptions = _.map(availableLocales, (availableLocale) => ({
    value: availableLocale,
    label: t('ui.locale', { context: availableLocale }),
  }))

  // salutation
  const salutationOptions = _.map(_.compact(salutationSelection), (sal) => ({
    value: sal,
    label: t('screens.registration.form.salutation.options', { context: sal }),
  }))

  // transform the countries to usable format in select
  const countryCallingCodeOptions = useMemo(() => (
    countriesEntities.map((country) => ({
      label: country.callingCode,
      value: country.callingCode,
    }))
  ), [countriesEntities])

  // useMemo for caching variables
  const formInitialValues = useMemo(
    () => (
      getRegistrationFormInitialValues({
        formConfig,
        defaultCountryCallingCode,
        defaultLocale,
        defaultValues,
      })
    ),
    [defaultLocale, defaultCountryCallingCode, formConfig, defaultValues],
  )

  // const formValidationSchema = useMemo(() => (
  //   transformRegistrationFormValidationSchema(formConfig)
  // ), [formConfig])
  const formValidationSchema = useMemo(() => {
    const requireEmailByRegisterApproach = _.every(supportedRegisterApproaches, {
      inputType: 'email',
    })
    const requireEmailTokenByRegisterApproach = _.some(supportedRegisterApproaches, {
      verificationType: 'emailToken',
    })
    const requirePhoneByRegisterApproach = _.every(supportedRegisterApproaches, {
      inputType: 'phone',
    })
    const requireSmsTokenByRegisterApproach = _.some(supportedRegisterApproaches, {
      verificationType: 'smsToken',
    })
    const modifiedFormConfig = {
      ...formConfig,
      email: {
        ..._.get(formConfig, 'email', {}),
        required: _.get(formConfig, 'email.required', false) || requireEmailByRegisterApproach,
        requireToken:
          _.get(formConfig, 'email.requireToken', false) || requireEmailTokenByRegisterApproach,
      },
      phone: {
        ..._.get(formConfig, 'phone', {}),
        required: _.get(formConfig, 'phone.required', false) || requirePhoneByRegisterApproach,
        requireToken:
          _.get(formConfig, 'phone.requireToken', false) || requireSmsTokenByRegisterApproach,
      },
    }
    return transformRegistrationFormValidationSchema(modifiedFormConfig)
  }, [formConfig, supportedRegisterApproaches])

  const requiredFields = useMemo(() => (
    _.reduce(formConfig, (result, current, key) => {
      const newResult = result
      if (current.required) {
        newResult[key] = true
        if (current.requireConfirmation) {
          newResult[`${key}Confirmation`] = true
        }
      }
      return newResult
    }, {})
  ), [formConfig])

  /**
   * fetchCountriesApi
   * function to call api and fetch countries for country calling code options
   */
  const fetchCountriesApi = useCallback(async () => {
    try {
      const fetchCountriesApiQuery = getSystemSetting('api.v2.addresses.countries.registration.ecom.query', {})
      const { countries } = await fetchCountries({
        params: {
          pageSize: 999,
          ...fetchCountriesApiQuery,
        },
        arrayFormat: 'brackets',
      })
      const countriesCodes = _.split(preferredCountryCodes, ' ')
      const firstCountryCode = _.head(countriesCodes)
      const firstCountry = _.find(countries, { alpha2: firstCountryCode }) || _.first(countries)
      setCountriesEntities(countries)
      setDefaultCountryCallingCode(_.get(firstCountry, 'callingCode'))
      setFormDisabled(false)
      setPageReady(true)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSystemSetting, fetchCountries])

  /**
   * handle EmailTokenButton callbacks
   */
  function onRequestEmailTokenSuccess() {
    setShowEmailVerificationCodeInput(true)
    // setShowVerificationCodeInput(false)
  }
  function onRequestEmailTokenError(error) {
    const generalError = _.get(error, 'generalError', {})
    alert.show(generalError.message)
  }

  /**
   * handle SmsTokenButton callbacks
   */
  function onRequestSmsTokenSuccess() {
    setShowVerificationCodeInput(true)
    // setShowEmailVerificationCodeInput(false)
  }
  function onRequestSmsTokenError(error) {
    const generalError = _.get(error, 'generalError', {})
    alert.show(generalError.message)
  }

  /**
   * handleMergeCart
   * after user registration success, if a guest cart
   */
  async function handleMergeCart() {
    if (
      _.isEmpty(cartId)
      // skip create cart on dine in mode
      || _.isEqual(_.get(orderMethod, 'commerceType'), 'dineIn')
    ) return
    const options = {
      params: {
        includes: ['cart_line_properties', 'cart_shipment_ids'],
      },
    }
    try {
      const origCartShipment = _.get(cart, 'cartShipments.0', {})
      const { cart: mergedCart } = await mergeCart(options)
      if (!_.isEmpty(orderMethod)) {
        const deliveryType = _.get(orderMethod, 'deliveryType')
        const deliveryTypeForWarehouse = _.get(orderMethod, 'deliveryTypeForWarehouse')
        const selectedStoreId = _.get(store, 'id')
        const selectedDeliveryType = (selectedStoreId === 'warehouse' && deliveryTypeForWarehouse)
          || deliveryType
          || _.get(origCartShipment, 'deliveryType.code')
        const updateDeliveryTypeAction = _.omitBy({
          actionType: 'update_cart_shipment',
          id: _.get(mergedCart, 'cartShipmentIds.0'),
          deliveryType: selectedDeliveryType,
          deliveryAddressId: _.get(origCartShipment, 'deliveryAddress.id'),
          pickupStoreId: (selectedStoreId !== 'warehouse' ? selectedStoreId : _.get(origCartShipment, 'pickupStore.id')),
        }, _.isNil)
        await updateCart({
          cartId: _.get(mergedCart, 'id'),
          payload: {
            data: {
              actions: [
                updateDeliveryTypeAction,
              ],
            },
            batchUpdateMode: 2,
          },
          params: getParams({
            includeGroups: ['basic'],
          }),
        })
      }

      // deliveryType, deliveryAddressId, pickupStoreId
    } catch (error) {
      // continue the login process
      console.warn('[Project] handleMergeCart error: ', error)
    }
  }

  /**
   * handleRegistration
   *
   * @param {*} values, object contain all value from input
   */
  async function handleRegistration(values) {
    alert.remove()
    setFormDisabled(true)

    const userRegistrationFields = [
      ..._.keys(formConfig),
      'token',
      'emailToken',
    ]
    const approach = getRegistrationApproach(values)

    // prepare api call payload
    const data = {
      registration: {
        approach,
        ..._.omitBy(_.pick(values, userRegistrationFields), _.isNil),
      },
    }

    // calling api for registration and control the flow of page redirect
    try {
      const { session } = await createRegistration(data)

      // update auth token and user id
      await setAuth({
        ...auth,
        ..._.pick(session, ['authToken', 'userId']),
      })

      await handleMergeCart()

      try {
        trackEvent('customerRegister', {}, {
          user: _.pick(values, ['email', 'phone', 'firstName', 'lastName']),
        })
      } catch (ex) {
        // do nothing
      }

      // handle redirect to different page after sign in successfully
      navigate(
        _.isEmpty(redirectUrl) ? '/account/' : redirectUrl,
        {
          replace: true,
          state: callbackState,
        },
      )
    } catch (error) {
      setFormDisabled(false)
      throw error
    }
  }

  /**
   * handleSubmit
   *
   * Formik onSubmit callback
   *
   * @param {*} values form values from Formik
   * @param {*} actions includes an object containing a subset of the injected props and methods
   */
  const handleSubmit = async (values, actions) => {
    alert.remove()
    try {
      await handleRegistration(values)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      const validationError = _.get(error, 'validationError', {})
      const errorMessage = _.isEmpty(validationError.message)
        ? generalError.message
        : validationError.message
      alert.show(errorMessage)
    } finally {
      actions.setSubmitting(false)
    }
  }

  /**
   * onSocialSignIn
   *
   * redirect facebook
   * TODO implement logical for other sacial platforms
   */

  async function onSocialSignIn(provider) {
    alert.remove()
    const url = new URI(location.href)
    // use a cross-platform application like NGROK to test in localhost
    const urlRedirect = `${url.protocol()}://${url.host()}/${pathContext.locale}/oauth/register`

    // prepare api call payload
    const data = {
      omniAuthOtp: {
        provider,
        mode: 'redirect',
        redirect: false,
        redirectUrl: urlRedirect,
      },
    }

    // calling api for create session and control the flow of page redirect
    try {
      const { omniAuthOtp } = await createUsersOmniAuthOtps(data)
      const urlAPI = _.get(api, 'host')
      const urlAuth = _.get(omniAuthOtp, 'requestPhasePath')

      const newUrl = `${urlAPI}${urlAuth}`
      // handle redirect to different page after sign in successfully
      navigate(
        newUrl,
        { replace: false },
      )
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)

      setFormDisabled(false)

      throw error
    }
  }

  const onLoginClick = () => {
    // pass through state to login form
    navigate(
      '/login/',
      _.pick(location, ['state']),
    )
  }

  /**
   * redirect to account page if user is already logged in
   */
  useEffect(() => {
    if (auth.userId) {
      navigate('/account/', { replace: true })
    }
  }, [])

  /**
   * get countries for country call code option
   */
  useEffect(() => {
    fetchCountriesApi()

    return () => {
      cancelRequest.cancelAll(['fetchCountries'])
    }
  }, [fetchCountriesApi])

  /**
   * Show token input if defaultValues exist
   */
  useEffect(() => {
    const {
      token,
      emailToken,
    } = defaultValues || {}
    if (!_.isEmpty(token)) {
      setShowVerificationCodeInput(true)
    }
    if (!_.isEmpty(emailToken)) {
      setShowEmailVerificationCodeInput(true)
    }
  }, [defaultValues])

  /**
   * cancel api call when leaving the page
   */
  useEffect(() => () => (
    cancelRequest.cancelAll(['createRegistration'])
  ), [])

  const viewProps = {
    pageReady,
    seoTitle,
  }

  const formPorps = {
    countryCallingCodeOptions,
    genderSelection,
    localeOptions,
    formConfig,
    formDisabled,
    lastNameFirst,
    requiredFields,
    showEmailVerificationCodeInput,
    showVerificationCodeInput,
    socialAvailable,
    privacyUrl,
    salutationOptions,
    supportedRegisterApproaches,
    termsUrl,
    onLoginClick,
    onRequestEmailTokenSuccess,
    onRequestEmailTokenError,
    onRequestSmsTokenSuccess,
    onRequestSmsTokenError,
    onSocialSignIn,
  }

  return (
    <RegistrationView {...viewProps}>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validateOnChange
        validationSchema={formValidationSchema}
        onSubmit={handleSubmit}
      >
        <RegistrationForm {...formPorps} />
      </Formik>
    </RegistrationView>
  )
}

export default RegistrationController
